'use client'

import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { orderBy, some } from 'lodash-es'
import { useState } from 'react'
import { ModalTrigger } from '../modal/ModalTrigger'
import { PageNavigator, PageNavigatorOption } from '../page/PageNavigator'
import { UserCommunities } from '../site/UserCommunities'
import { UserCompetitions } from '../site/UserCompetitions'
import { UserParties } from '../site/UserParties'
import { UserSquads } from '../site/UserSquads'
import { ContactFlow } from '@/flows/Site/ContactFlow/_ContactFlow'
import { DiscoveryRoute, RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useUserSelfOrgCommunities } from '@/entity/user/useUserSelfOrgCommunities'
import { useGateway } from '@/entity/site/useGateway'
import { useMyActivities } from '@/entity/party-finder/useMyActivities'
import { useOrganizationUrls } from '@/entity/organization/useOrganizationUrls'
import { useCompetitionUserView } from '@/entity/competition/useCompetitionUserView'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useNavigation } from '@/core/hooks/useNavigation'
import { extractIconStrings } from '@/components/molecules/input/ManageUrls'
import { useOrgTutorialTasks } from '@/components/pages/Home/components/Tutorial'

/**
 * Container for primary site navigation options.
 */
export const NavigationMain = ({ expanded = true }: { expanded?: boolean }) => {
  // match discovery + additional related routes
  const { orgCommunities } = useUserSelfOrgCommunities({ suspense: false })
  const { userView } = useCompetitionUserView({ suspense: false })
  const {
    featHideCommunity,
    featLimitedUnauthed,
    featShopLink,
    featCMSOnly,
    featSquads,
    featElasticResources,
    cfgContactIframe
  } = useFeatures()
  const { user } = useFirebase()
  const { isComplete, featPartyFinderActivity } = useGateway()
  const { filtered } = useOrgTutorialTasks()
  const { urls } = useOrganizationUrls()
  const { getPath, path } = useNavigation()
  const { activities } = useMyActivities()
  const hasCompetitions = some(userView?.ongoing) || some(userView?.upcoming)

  const [contactFlowOpen, setContactFlowOpen] = useState(false)

  const navItems: PageNavigatorOption[] = []

  if (isComplete || (!featLimitedUnauthed && !user)) {
    navItems.push({
      icon: <RcIcon icon={['fal', 'home']} />,
      text: <RcTrans i18nKey='navigation.home' />,
      to: getPath({
        root: RootRoute.Root
      }),
      alert: filtered.length
    })

    !featCMSOnly &&
      navItems.push({
        icon: <RcIcon icon={['fal', 'trophy']} />,
        text: <RcTrans i18nKey='navigation.competitions' />,
        to: getPath({
          root: RootRoute.Discovery,
          subRoute: DiscoveryRoute.Competitions
        }),
        active: path.startsWith(
          getPath({
            root: RootRoute.Competition
          })
        ),
        ExpandableComponent: hasCompetitions ? UserCompetitions : undefined
      })

    !featHideCommunity &&
      !featCMSOnly &&
      navItems.push({
        icon: <RcIcon icon={['fal', 'school']} />,
        text: <RcTrans i18nKey='navigation.communities' />,
        to: getPath({
          root: RootRoute.Discovery,
          subRoute: DiscoveryRoute.Communities
        }),
        active: path.startsWith(
          getPath({
            root: RootRoute.Community
          })
        ),
        ExpandableComponent: some(
          orgCommunities.filter(it => it.kind === CommunityKind.BASIC)
        )
          ? UserCommunities
          : undefined
      })

    featSquads &&
      !featCMSOnly &&
      navItems.push({
        icon: <RcIcon icon={['fal', 'users']} />,
        text: <RcTrans i18nKey='navigation.squads' />,
        to: getPath({
          root: RootRoute.Discovery,
          subRoute: DiscoveryRoute.Squads
        }),
        active: path.startsWith(
          getPath({
            root: RootRoute.Squad
          })
        ),
        ExpandableComponent: some(
          orgCommunities.filter(it => it.kind === CommunityKind.TEAM)
        )
          ? UserSquads
          : undefined
      })
  }

  featPartyFinderActivity &&
    navItems.push({
      icon: <RcIcon icon={['fal', 'users-crown']} />,
      text: <RcTrans i18nKey='navigation.party-finder' />,
      to: getPath({
        root: RootRoute.PartyFinder
      }),
      active: path.startsWith(
        getPath({
          root: RootRoute.PartyFinder
        })
      ),
      end: true,
      ExpandableComponent: some(activities) ? UserParties : undefined
    })

  navItems.push({
    icon: <RcIcon icon={['fal', 'newspaper']} />,
    text: <RcTrans i18nKey='navigation.news' />,
    to: getPath({
      root: RootRoute.Blogs
    })
  })

  featElasticResources &&
    navItems.push({
      icon: <RcIcon icon={['fal', 'info-circle']} />,
      text: <RcTrans i18nKey='navigation.resources' />,
      to: getPath({
        root: RootRoute.Resources
      })
    })

  featShopLink &&
    navItems.push({
      icon: <RcIcon icon={['fal', 'shopping-cart']} />,
      text: <RcTrans i18nKey='navigation.shop' />,
      href: featShopLink as string
    })

  const customNavLinks = orderBy(
    urls.filter(it => it.options === 'highlight'),
    ['ordinal'],
    ['desc']
  )

  customNavLinks?.forEach(customLink => {
    navItems.push({
      icon: <RcIcon icon={extractIconStrings(customLink.description)} />,
      text: customLink.name,
      href: customLink.url,
      sameTab: true,

      active: path.startsWith(customLink.url!)
    })
  })

  navItems.push({
    icon: <RcIcon icon={['fal', 'messages-question']} />,
    text: <RcTrans i18nKey='shared.get-help-button' />,
    action: () => setContactFlowOpen(true)
  })

  return (
    <>
      <PageNavigator
        storageKey='nav-main'
        options={navItems}
        variant='vertical'
        aria-label='primary site navigation'
        iconOnly={!expanded}
        tier1
      />
      <ModalTrigger
        open={contactFlowOpen}
        onClose={() => setContactFlowOpen(false)}
        modalProps={{
          noPadding: !!cfgContactIframe,
          noFooter: !!cfgContactIframe
        }}
      >
        {({ handleClose }) => {
          return cfgContactIframe ? (
            <iframe
              src={cfgContactIframe as string}
              title='contact-override'
              style={{ flexGrow: 1, width: '100%', minHeight: 500, border: 0 }}
            />
          ) : (
            <ContactFlow onComplete={handleClose} />
          )
        }}
      </ModalTrigger>
    </>
  )
}
