'use client'

import Paper from '@mui/material/Paper'
import { some } from 'lodash-es'
import { usePathname } from 'next/navigation'
import { BlockedLayout } from './BlockedLayout'
import { MainLayoutV4 } from './MainLayoutV4'
import { UnauthenticatedLayout } from './UnauthenticatedLayout'
import { useGAHistory } from '@/components/organisms/site/GAHistory'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useKonami } from '@/core/hooks/useKonami'
import { useLocale } from '@/core/hooks/useLocale'
import { useOrganizationLocale } from '@/core/hooks/useOrganizationLocale'
import {
  useOrgRedirect,
  useRedirectHistory,
  useXState
} from '@/core/hooks/useRedirects'
import { RootRoute } from '@/core/route-keys'
import { calcElevationString } from '@/style/palette'

export const BLOCKED_LAYOUT_ROUTES = [
  RootRoute.Login,
  RootRoute.Registration,
  RootRoute.Register,
  RootRoute.ResetPassword,
  RootRoute.EmailAuthRedirect,
  RootRoute.Oauth2,
  RootRoute.OauthCallback,
  RootRoute.FrostGiantSteam,
  RootRoute.FrostGiantSteamCallback,
  RootRoute.FrostGiantSteamError,
  RootRoute.VerifyDomainCode,
  RootRoute.VerifyEmailCode,
  RootRoute.MfaLink
]

export const LayoutPicker = ({ children }: { children: React.ReactNode }) => {
  const { user } = useFirebase()
  const { devLoggedOut, noLayout } = useFeatures()
  const pathname = usePathname()

  useLocale()
  const initialized = useOrganizationLocale()
  useRedirectHistory()
  useOrgRedirect()
  useGAHistory()
  useKonami()
  useXState()

  const skipLayout = noLayout || pathname.includes('embed')
  const isBlockedRoute = some(BLOCKED_LAYOUT_ROUTES, it =>
    pathname.includes(it)
  )
  const isLoggedIn = user && !devLoggedOut && !isBlockedRoute

  if (!initialized) return null
  if (skipLayout) return children

  return (
    <Paper
      elevation={3}
      square
      sx={theme => ({
        backgroundColor: theme.palette.primary.main,
        backgroundImage: calcElevationString(theme.palette.mode, [3]),
        width: '100dvw',
        minWidth: '100dvw',
        height: '100dvh',
        minHeight: '100dvh',
        position: 'fixed',
        overflowY: 'scroll',
        overflowX: 'hidden'
      })}
    >
      {isLoggedIn ? (
        <MainLayoutV4>{children}</MainLayoutV4>
      ) : isBlockedRoute ? (
        <BlockedLayout>{children}</BlockedLayout>
      ) : (
        <UnauthenticatedLayout>{children}</UnauthenticatedLayout>
      )}
    </Paper>
  )
}
